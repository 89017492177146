import { render, staticRenderFns } from "./Registro_crear.vue?vue&type=template&id=f5b9f710&scoped=true"
import script from "./Registro_crear.vue?vue&type=script&lang=js"
export * from "./Registro_crear.vue?vue&type=script&lang=js"
import style0 from "./Registro_crear.vue?vue&type=style&index=0&id=f5b9f710&prod&scoped=true&lang=css"
import style1 from "./Registro_crear.vue?vue&type=style&index=1&id=f5b9f710&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5b9f710",
  null
  
)

export default component.exports